import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useFafsaRepostsList(root) {
  // test
  const toast = useToast()

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const refFafsaReportsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Card Name', sortable: false },
    { key: 'created_by', label: 'Created By', sortable: false },
    { key: 'created_at', label: 'Created At', sortable: false },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('fafsaPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const isPdfLoading = ref(false)
  const selectedCard = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refFafsaReportsListTable.value.refresh()
  }

  watch(programId, () => {
    refetchData()
  })

  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-fafsa-reports', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-fafsa-reports', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-fafsa-reports', 'searchQuery', searchQueryVal)
        }, 250)
      }
      refetchData()
    })

  const fetchReportsList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      programId: programId.value,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value

    store
      .dispatch('app-fafsa/fetchReportList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Tuition Cards' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteReport = ID => axios
    .delete(`auth/fafsa/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting report',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const exportPdf = (id, queryParams) => axios
    .post(`/auth/fafsa/${id}/pdf`, queryParams, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting pdf',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const exportBalanceReport = id => axios
    .post(`/auth/fafsa/${id}/balance-report`, {}, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting balance report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  return {
    fetchReportsList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    isPdfLoading,
    selectedCard,
    refFafsaReportsListTable,
    programId,

    deleteReport,
    exportPdf,
    exportBalanceReport,

    refetchData,
  }
}
