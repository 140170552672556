<template>
  <ValidationObserver
    ref="exportPdfModalRule"
  >
    <b-modal
      id="export-pdf-modal"
      ref="export-pdf-modal"
      :title="title"
      centered
      no-close-on-backdrop
      ok-title="Export"
      ok-only
      :ok-disabled="isLoading"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      :hide-header-close="isLoading"
      @show="setModalData"
      @hidden="resetModal"
      @ok.prevent="handleExportPdf"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form-group
          label="Export mode"
        >
          <b-form-radio
            v-model="isSinglePdfFile"
            class="mb-1"
            name="exportMode"
            :value="true"
          >
            Export as a single PDF file
          </b-form-radio>
          <b-form-radio
            v-model="isSinglePdfFile"
            name="exportMode"
            :value="false"
          >
            Export as separate PDF files
          </b-form-radio>
        </b-form-group>

        <br>
        <b-form-group
          label="Tuition Card Template"
          class="mb-3"
        >
          <validation-provider
            #default="{ errors }"
            name="tuition card template"
            rules="required"
          >
            <v-select
              v-model="selectedTemplate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="templatesList"
              :clearable="false"
              :reduce="template => template.id"
              :placeholder="'Select Template'"
              :class="{'is-invalid': errors.length > 0 }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Export type"
        >
          <b-form-radio
            v-model="isSendByEmail"
            class="mb-1"
            name="exportType"
            :value="false"
          >
            Download file
          </b-form-radio>
          <b-form-radio
            v-model="isSendByEmail"
            name="exportType"
            :value="true"
          >
            Send file by email
          </b-form-radio>
        </b-form-group>
        <div
          v-if="isSendByEmail"
        >
          <hr>
          <h5
            class="text-center py-1"
          >
            This process may take a while. You will receive an email with the csv file when complete. Please enter email below.
          </h5>
          <b-form-group
            class="mb-2"
          >
            <!-- Email -->
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email to send"
                label-for="email"
              >
                <b-form-input
                  v-model="selectedEmail"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </div>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
  BFormRadio,
  BFormInput,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useExportPdfModal from '@/views/components/fafsa-export-pdf-modal/useExportPdfModal'

export default {
  components: {
    BFormInput,
    ValidationProvider,
    ValidationObserver,

    vSelect,
    BModal,
    BOverlay,
    BFormGroup,
    BFormRadio,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Export to PDF',
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isSinglePdfFile: true,
      isSendByEmail: false,
      templatesList: [],
      selectedTemplate: null,
      selectedEmail: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
  },
  setup() {
    const {
      fetchTuitionCardTemplatesForSelect,
    } = useExportPdfModal()

    return {
      fetchTuitionCardTemplatesForSelect,
    }
  },
  async created() {
    const queryParams = {
      program_id: this.programId,
    }
    this.templatesList = await this.fetchTuitionCardTemplatesForSelect(queryParams)
      .then(response => response.reduce((arr, item) => {
        arr.push({ id: item.id, label: item.name })
        return arr
      }, []))
  },
  methods: {
    setModalData() {
      this.selectedTemplate = null
      this.selectedEmail = this.userData.email ?? ''
      this.isSendByEmail = false
    },
    handleExportPdf() {
      return new Promise((resolve, reject) => {
        this.$refs.exportPdfModalRule.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('handleExportPdf', this.isSinglePdfFile, this.selectedTemplate, this.isSendByEmail, this.selectedEmail)
          } else {
            reject()
          }
        })
      })
    },
    resetModal() {
      this.isSinglePdfFile = true
    },
  },
}
</script>

<style>

</style>
